import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static values = {
    url: String,
    triggerOnVisible: { type: Boolean, default: false },
    refreshInterval: Number
  };

  hasUrlValue!: boolean;
  urlValue!: string;

  triggerOnVisibleValue!: boolean;
  fetched: boolean = false;
  refreshIntervalValue!: number;
  refreshTimer!: ReturnType<typeof setInterval>;

  connect() {
    useIntersection(this);
    if (!this.hasUrlValue) {
      console.error('[content-loader] You need to pass an url to fetch the remote content.');
      return;
    }
    console.log(this.triggerOnVisibleValue);
    if (!this.triggerOnVisibleValue) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  fetchContent() {
    fetch(this.urlValue, {
      headers: new Headers({
        'x-content-loader-request': 'true'
      })
    })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.fetchContent();
    }, this.refreshIntervalValue);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
